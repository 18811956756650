import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        viewProject: { Name: 'Project' },
        user: { FullName: '', isAuthed: false },
        authed: false,
        single: false,
        online: true,
        tokenExpired: true,
        wfEnabled: false,
        wfChecked: false
    },
    mutations: {
        viewProject (state, payload) {
            state.viewProject = payload;
        },
        user (state, payload) {
            state.user = payload;
        },
        authed (state, payload) {
            state.authed = payload;
        },
        tokenExpired (state, payload) {
            state.tokenExpired = payload;
        },
        wfChecked (state, payload) {
            state.wfChecked = payload;
        },
        wfEnabled (state, payload) {
            state.wfEnabled = payload;
        },
        onlineStatus (state, payload) {
            state.online = payload;
        },
        reset (state) {
            state.viewProject = { Name: 'Project' };
            state.user = { FullName: '', isAuthed: false };
        },
        gpsDisplay (state, payload) {
            state.viewProject.GPS = payload;
        },
        single (state, payload) {
            state.single = payload;
        }
    },
    actions: {
        isAuthed ({ commit }) {
            commit('authed', true);
        },
        isOnline ({ commit, state }) {
            if (navigator.onLine) {
                if (state.online !== true) {
                    commit('onlineStatus', true);
                }
            }
            else {
                if (state.online !== false) {
                    commit('onlineStatus', false);
                }
            }
        },
        notAuthed ({ commit }) {
            commit('authed', false);
        },
        tokenExpired ({ commit }) {
            commit('tokenExpired', true);
        },
        tokenValid ({ commit }) {
            commit('tokenExpired', false);
        },
        reset ({ commit }) {
            commit('reset', false);
        },
        isSingle ({ commit }) {
            commit('single', true);
        },
        wfIsEnabled ({ commit }) {
            commit('wfEnabled', true);
            commit('wfChecked', true);
        },
        wfIsDisabled ({ commit }) {
            commit('wfEnabled', false);
            commit('wfChecked', true);
        },
    },
    modules: {
    }
});
